body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a.rs-navbar-item:hover {
    text-decoration: none;
    color: inherit;
}

.rs-table {
    font-family: "Roboto Mono", "Liberation Mono", "Droid Sans Mono", Consolas, monospace;
    font-size: 10pt;
}

#root {
    height: 100vh;
}
